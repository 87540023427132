<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>训练营佣金规则</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <!--标题-->
            <el-row>
                <el-col :span="12"><h3>{{ page_name }}</h3></el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--列表-->
            <el-table
                    v-loading="loading"
                    :data="tableData"
                    border
                    size="medium"
                    style="width: 100%">
                <el-table-column
                        prop="grade_name"
                        label="等级"
                        width="180">
                </el-table-column>
                <el-table-column
                        prop="content"
                        label="内容">
                    <template slot-scope="scope">
                        {{ scope.row.content }}
                    </template>
                </el-table-column>
                <el-table-column
                        fixed="right"
                        label="操作"
                        width="150">
                    <template slot-scope="scope">
                        <el-button
                                v-if="is_auth('coach.tag.issave')"
                                @click="edit_page(scope.row)"
                                size="mini">编辑
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!--编辑-->
            <el-dialog v-loading="loading"
                       v-if="is_auth('coach.rules.savecamp')"
                       title="编辑规则"
                       :visible.sync="editPage"
                       width="70%"
                       :close-on-press-escape="false"
                       :close-on-click-modal="false"
            >
                <el-row>
                    <el-form size="medium" ref="form" :label-width="this.env.label_width">
                        <el-col :span="24">
                            <el-form-item label="等级：">
                                <span>{{ this.grade_name }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item v-for="(item,index) in this.info" :key="index" :label="'规则'+(index+1)+'：'">
                                <el-input-number
                                        v-if="index===0"
                                        size="mini"
                                        v-model="item.reservation_percentage_head"
                                        :step="1" :min="0" :max="100"
                                        step-strictly>
                                </el-input-number>
                                <el-input-number
                                        v-else
                                        size="mini"
                                        v-model="item.reservation_percentage_head"
                                        :step="1" :min="info[(index-1)].reservation_percentage_tail" :max="100"
                                        step-strictly>
                                </el-input-number>
                                %
                                &lt;= 预约率 &lt;
                                <el-input-number
                                        size="mini"
                                        v-model="item.reservation_percentage_tail"
                                        :step="1" :min="item.reservation_percentage_head" :max="100"
                                        step-strictly>
                                </el-input-number>
                                %，佣金比例
                                <el-input-number
                                        size="mini"
                                        v-model="item.salary_percentage"
                                        :step="1" :min="0" :max="100"
                                        step-strictly>
                                </el-input-number>
                                %
                                <el-button
                                        v-if="index >0"
                                        type="text" style="color: #F56C6C; margin-left: 20px"
                                        @click="delrules(index)">删除
                                </el-button>
                            </el-form-item>
                            <el-form-item label="">
                                <el-button type="text" @click="addrules">增加规则</el-button>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="">
                                <span class="form-tip">假设课程单价￥
                                    <el-input-number
                                            size="mini"
                                            v-model="example.price"
                                            :step="0.01" :min="0" :max="9999"
                                            step-strictly>
                                    </el-input-number>
                                    ，人数上限
                                    <el-input-number
                                            size="mini"
                                            v-model="example.quota"
                                            :step="1" :min="0" :max="999"
                                            step-strictly>
                                    </el-input-number>
                                    人，预约数
                                    <el-input-number
                                            size="mini"
                                            v-model="example.bookings_sum"
                                            :step="1" :min="0" :max="example.quota"
                                            step-strictly>
                                    </el-input-number>
                                    人。总金额:￥{{ this.Tool.bcmul(this.example.price, this.example.bookings_sum) }}
                                </span>
                                <br>
                                <span class="form-tip">
                                预约率：
                                {{ example.bookings_sum }}
                                ÷{{ example.quota }}
                                = {{ reservation_percentage() }}%
                                </span><br>
                                <span class="form-tip">
                                      佣金：
                                    ￥{{ this.Tool.bcmul(this.example.price, this.example.bookings_sum) }}
                                    ×{{ salary_percentage() }}%
                                    =￥{{ rules() }}
                                </span>

                            </el-form-item>
                        </el-col>
                    </el-form>
                </el-row>
                <el-row style="margin: 10px 0px;">
                    <el-button size="medium" type="primary" @click="save">确 定</el-button>
                    <el-button size="medium" @click="editPage = false">取 消</el-button>
                </el-row>

            </el-dialog>
            <div style="height: 20px;"></div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            page_name: '训练营佣金规则',
            loading: true,          // 加载状态
            tableData: [],          // 列表内容
            id: '',
            grade_name: '',
            info: [],            // 编辑数据
            editPage: false,
            example: {
                price: 89,
                quota: 15,
                bookings_sum: 10,
            },                     // 例子
        }
    },
    // 创建
    created() {
        this.getlist();
        this.init()
    },
    // 安装
    mounted() {
    },
    methods: {
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 获取列表
        getlist() {
            let postdata = {
                api_name: "coach.rules.getcamplist",
                token: this.Tool.get_l_cache('token'),
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.tableData = json.data.list
                } else {
                    this.tableData = []
                    this.$message.error(json.message);
                }
            })
        },
        // 初始化
        init() {
            this.info = [
                {
                    reservation_percentage_head: 0,
                    reservation_percentage_tail: 0,
                    salary_percentage: 0,
                }
            ]
        },
        // 编辑页面
        edit_page(row = {}) {
            this.id = row.id
            this.grade_name = row.grade_name

            if (!this.Tool.is_empty(row.content)) {
                this.info = JSON.parse(JSON.stringify(row.content));
            } else {
                this.init()
            }

            this.editPage = true    // 显示页面
        },
        // 保存
        save() {
            let postdata = {
                api_name: 'coach.rules.savecamp',
                token: this.Tool.get_l_cache('token'),
                id: this.id,
                content: this.info,
            }
            // console.log('postdata', postdata)

            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.editPage = false
                    this.$message({
                        message: '保存成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getlist()
                        }
                    });

                } else {
                    this.$message.error(json.message);
                }
            })
        },
        // 增加规则
        addrules() {
            let rules_l = JSON.parse(JSON.stringify(this.info[this.info.length - 1]))
            this.info.push({
                reservation_percentage_head: rules_l.reservation_percentage_tail,
                reservation_percentage_tail: 0,
                salary_percentage: 0,
            })
        },
        // 删除规则
        delrules(index) {
            this.info.splice(index, 1)
        },
        // 计算预约率
        reservation_percentage() {
            let reservation_percentage = this.Tool.bcmul(this.example.bookings_sum / this.example.quota, 100).toFixed(2)

            return reservation_percentage
        },
        // 确定佣金百分比
        salary_percentage() {
            let reservation_percentage = this.reservation_percentage()
            let salary_percentage = 0
            for (let v of this.info) {
                console.log(reservation_percentage)
                if (reservation_percentage == 100) {
                    if (v.reservation_percentage_head <= reservation_percentage && reservation_percentage <= v.reservation_percentage_tail) {
                        salary_percentage = v.salary_percentage
                        break
                    }
                } else {
                    if (v.reservation_percentage_head <= reservation_percentage && reservation_percentage < v.reservation_percentage_tail) {
                        salary_percentage = v.salary_percentage
                        break
                    }
                }
            }
            return salary_percentage;
        },
        // 计算规则
        rules() {
            let salary_percentage = this.salary_percentage()
            let total = this.Tool.bcmul(this.example.price, this.example.bookings_sum)
            let salary = this.Tool.bcmul(total, (salary_percentage / 100)).toFixed(2);
            return salary;
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
